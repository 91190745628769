import React from "react";
import "./contact.css";
import Header from "components/Header";
import Footer from "components/Footer";
import ContactForm from "components/Form";

const Contact = () => {
  return (
    <div className="contact-component">
      <Header />
      <div className="contact">
        <h3>
          If you would like to contact me, you could either fill in the form
          below, or send an email to me
          <a href="mailto:vincentshiwork@gmail.com">vincentshiwork@gmail.com</a>
        </h3>
        <ContactForm className="contact-form" />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
