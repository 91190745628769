import React from "react";
import "./experience.css";
import Header from "components/Header";
import InfomediaLogo from "assets/images/infomedia-logo.jpg";
import UNSWLogo from "assets/images/unsw-logo.png";
import SATLogo from "assets/images/sat-logo.jpg";
import PearlerLogo from "assets/images/pearler-logo.png";
import MSALogo from "assets/images/MSALogo.png";
import IceBreakLogo from "assets/images/icebreak.svg";
import Footer from "components/Footer";

const Experience = () => {
  return (
    <div>
      <Header />
      <div className="experience">
        <h1>PROFESSIONAL EXPERIENCE</h1>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>SOFTWARE | DEVOPS ENGINEER - INFOMEDIA</h2>
              <h3>NOV 2021 - CURRENT</h3>
            </div>
            <img id="InfomediaLogo" src={InfomediaLogo} alt="Infomedia Logo" />
          </div>

          <ul>
            <li>
              Implemented backend APIs and business logic using Java and the
              Spring framework. Along with AWS Lambda Microservice to manage
              data from databases MongoDB, MySQL, and PostgreSQL.
            </li>
            <li>
              Monitored software and data using Athena, CloudWatch and New
              Relic.
            </li>
            <li>
              Handled DevOps and Data processing tasks, including building CI/CD
              infrastructure and maintaining pipelines with CircleCI and AWS
              services. Built and managed Data Pipeline (ETL) on Airflow,
              integrating Docker, ECS, EC2, CloudWatch, and Glue etc.
            </li>
            <li>
              Addressed critical on-call duties, maintaining software efficiency
              and reliability. Created and maintained documents.
            </li>
            <li>
              Coordinated with the Service Delivery Manager and engaged with
              customers to gather feedback and understand requirements.
            </li>
            <li>
              Progressed from a 3-month internship to a Software Engineer role,
              demonstrating skill in technical management and team
              collaboration.
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>RESEARCH STUDENT - UNSW</h2>
              <h3>FEB 2021 - DEC 2021</h3>
            </div>
            <img id="UNSWLogo" src={UNSWLogo} alt="UNSW Logo" />
          </div>

          <ul>
            <li>
              Researched Text Mining for Financial Forecasting, focusing on the
              influence of market sentiments on the stock market
            </li>
            <li>
              Achieved prediction accuracy rate: 80%. Received distinction in
              the final grade
            </li>
            <li>
              Analysed human-readable financial news fetched from the internet,
              aiming to increase the prediction accuracy of the U.S Stock
              Market, especially the share price
            </li>
            <li>
              Implemented and tested Data Acquisition, Pre-processing (data
              preparation & cleaning), Training & Results Evaluation
              (MLPClassifier Model, Random Forest Classifier Model, Passive
              Aggressive Classifier Model and Naïve Bayes for Multinomial
              Models)
            </li>
            <li>
              Relevant areas of knowledge: Python and its packages such as
              Pandas, NumPy, Scikit & NLTK, Data/Text Mining and Analysis, Big
              Data Management, Machine Learning, Natural Language Processing and
              full-stack tech such as ReactJS, RESTful API, HTML and CSS
            </li>
            <li>
              Demo slide deck:
              <a
                href="https://docs.google.com/presentation/d/18TAQidDVvtG7XdAKoTerMJjcxOCxDpHeeBO8d6hso5k/edit?
              usp=sharing"
              >
                click here
              </a>
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>DEVELOPER INTERN - SAT PTY LTD</h2>
              <h3>AUG 2021 - OCT 2021</h3>
            </div>
            <img id="SATLogo" src={SATLogo} alt="SAT Logo" />
          </div>

          <ul>
            <li>
              Designed and built Client's Database locally by preparing and
              managing required information from external organisations.
              Automated processes such as Database query, file generation and
              its submission
            </li>
            <li>
              Relevant areas of knowledge: Database design and creation by
              applying Python script (sqlite3), SQLite and MySQL conversion, XML
              file auto-generation & manipulation, AWS CodeCommit, Git usage
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>
                WEB APPLICATION DEVELOPER INTERN - PEARLER INVESTMENTS PTY LTD
              </h2>
              <h3>JUN 2020 - NOV 2020</h3>
            </div>
            <img id="PearlerLogo" src={PearlerLogo} alt="Pearler Logo" />
          </div>

          <ul>
            <li>
              Built Pearler's Financial Investment Social Timeline Web
              Application prototype and coded framework to form core features of
              social network websites
            </li>
            <li>
              Included main functions such as Post, Timeline Filtering,
              Investment Trending, Investor Following, and Personal Investment
              Analysis & Statistics pages
            </li>
            <li>
              Followed agile software development approach by weekly meeting
              with the founder to adjust product's design and development
            </li>
            <li>
              Relevant areas of work: ReactJS, HTML & CSS, Figma, Database
              Construction, and Web App design thinking
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>PROJECT STUDENT - MICROSOFT STUDENT ACCELERATOR</h2>
              <h3>APR 2020 - SEP 2020</h3>
            </div>
            <img id="MSALogo" src={MSALogo} alt="MSA Logo" />
          </div>

          <ul>
            <li>
              Acquired industrial training from Microsoft employees and past
              participants, and used these skills to build static/commercial
              websites
            </li>
            <li>
              Created a multi-page personal profile website, using several
              plugins such as Robo Gallery, Yoast SEO, Elementor, Contact Form 7
              and Google Analytics - MonsterInsights
            </li>
            <li>
              Relevant areas of knowledge: Python, Flask, WordPress Dashboards
              usage and management, Plugins usage, Power Apps, HTML & CSS, SQL
              and Deployment
            </li>
          </ul>
        </div>
        <h1>OTHER EXPERIENCE</h1>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>CASUAL WEB DEVELOPER - ICE BREAK GROUP</h2>
              <h3>AUG 2023 - CURRENT</h3>
            </div>
            <img
              id="IceBreakLogo"
              src={IceBreakLogo}
              alt="Ice Break Group Logo"
            />
          </div>
          <ul>
            <li>
              Rebrand Ice Break Group's website to a modern responsive booking
              system website by using ReactJS and CSS. Handle website form
              submission using Backend NodeJS server.
            </li>
            <li>
              Created and managed Staging server on AWS S3. Ensure the website
              migration process is smooth and no downtime.
            </li>
            <li>
              Integrated with Google Analytics and best SEO practices to ensure
              the website is SEO friendly.
            </li>
            <li>
              Relevant areas of knowledge: ReactJS, NodeJS, Restful API, AWS
              Services, Staging testing, Google Analytics, SEO, HTML & CSS
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Experience;
