import React from "react";
import "./education.css";
import LaptopIcon from "assets/images/laptop-icon.png";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import UNSWLogo from "assets/images/unsw-logo.png";
import Header from "components/Header";
import Footer from "components/Footer";

const Education = () => {
  return (
    <div>
      <Header />
      <div className="education">
        {/* <h1>Education</h1> */}
        <div className="education-history">
          <img src={UNSWLogo} />
          <h1>BACHELOR OF ENGINEERING (HONS) - SOFTWARE ENGINEERING</h1>
          <h2>UNIVERSITY OF NEW SOUTH WALES</h2>
          <h3>FEB 2018 - DEC 2021</h3>
          <p>
            My professional journey commenced at UNSW, where my studies in
            software engineering laid a robust foundation for my career. At
            UNSW, the program fostered a deep understanding of the principles
            and practices necessary for success in software engineering. It also
            honed my ability to rapidly assimilate new knowledge, a vital skill
            in the ever-evolving tech landscape.
          </p>
          <p>
            Throughout my academic journey, I explored a wide range of subjects
            within software engineering, ranging from programming languages and
            software design to database management etc. These courses were
            instrumental in shaping my understanding of the lifecycle of
            software development and product creation. In addition to academic
            pursuits, my university time was enriched by hands-on experiences
            through internships and research projects. These practical
            engagements were crucial in bridging the gap between academic
            theories and real-world industry, smoothing my transition from a
            student to a professional software engineer. I regard my time at
            UNSW as invaluable; it equipped me not only with the technical
            expertise but also with the passion to create impactful software
            products. As I step into the industry, I carry with me not just the
            knowledge and skills, but also a commitment to continuous learning
            and growth. I am eager to contribute significantly to the field of
            software engineering and am confident in my ability to make a
            meaningful impact.
          </p>
          <br />
          <p>Relevant subjects:</p>
          {/* <div className="relevant-subject"> */}
          <div className="relevant-subject">
            <ul>
              <li>Software Engineering Fundamentals</li>
              <li>Object-Oriented Design and Programming</li>
              <li>Data Structures and Algorithms</li>
              <li>Web Front-end Programming</li>
              <li>Database Systems</li>
              <li>Advanced C++ Programming</li>
              <li>Information Retrieval and Web Search</li>
              <li>Computer Networks and Applications</li>
              <li>Research Thesis</li>
              <li>Artificial Intelligence</li>
              <li>Software Engineering Workshop</li>
              <li>Software Construction</li>
              <li>Software System Design and Implementation</li>
            </ul>
          </div>
          <div className="relevant-subject">
            <ul>
              <li>Human Computer Interaction</li>
              <li>Engineering Design</li>
              <li>Computer Systems</li>
              <li>System Modelling and Design</li>
              <li>Data Management for Statistical Analysis</li>
              <li>Probability, Statistics and Information</li>
              <li>Discrete Mathematics</li>
              <li>Neural Networks and Deep Learning</li>
              <li>Information, Codes and Ciphers</li>
              <li>Requirements and Design Workshops</li>
              <li>Data Services Engineering</li>
              <li>Finite Mathematics</li>
              <li>Management and Ethics</li>
            </ul>
          </div>
        </div>
        {/* </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default Education;
