import React from "react";
import "./home.css";
import LaptopIcon from "assets/images/laptop-icon.png";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Header from "components/Header";
import Footer from "components/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <div className="home-profile">
        <div className="home-profile-top">
          <img src={LaptopIcon} alt="Laptop Icon" />
          <div className="home-profile-content">
            <h1>My name is Vincent Shi.</h1>
            <h2>Software Engineer | Full-stack Developer</h2>
            <p>Code, Creativity, and Life: Weaving Passion into Every Line</p>
          </div>
        </div>
        <div className="home-profile-logo">
          <a href="https://github.com/Vincent-SS">
            <GitHubIcon alt="GitHub Icon" />
          </a>
          <a href="https://www.linkedin.com/in/wenxuan-vincent-shi/">
            <LinkedInIcon alt="LinkedIn Icon" />
          </a>
        </div>
      </div>
      {/* <div className="home-about-me">
        <h1>About Me</h1>
        <p>
          Hello! My name is Vincent, and I am a Software Engineer at Infomedia,
          a global company offering SaaS solutions in the automotive industry. I
          hold an Honours degree in Software Engineering from the University of
          New South Wales (UNSW). Originally from Xi'an, China, I have made
          Sydney my home since 2017.
        </p>
        <br />
        <p className="home-about-me-bold-">
          My experience includes software development, web and mobile app
          implementation, along with cloud tool utilisation, cloud deployment
          (CI/CD), data processing, and database management. I have a strong
          interest in emerging technologies essential for developing great
          products.
        </p>
      </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
