import React from "react";
import "./gallery.css";
import Header from "components/Header";
import Footer from "components/Footer";
import Grid from "@mui/material/Unstable_Grid2";
import Horticultural from "assets/images/Horticultural.jpg";
import Gaol from "assets/images/gaol.jpg";
import OperaHarbour from "assets/images/opera-harbour.jpg";
import Singapore from "assets/images/singapore.jpg";
import Angkor from "assets/images/angkor.jpg";
import Flinder from "assets/images/flinder.jpg";
import Botany from "assets/images/botany.jpg";
import Floating from "assets/images/floating.jpg";
import Coogee from "assets/images/coogee.jpg";
import Seoul from "assets/images/seoul.jpg";
import Sky from "assets/images/sky.jpg";
import Circular from "assets/images/circular.jpg";
import Krivong from "assets/images/krivong.jpg";
import Cafe from "assets/images/cafe.jpg";
import Sunrise from "assets/images/sunrise.jpg";

const IndividualImageComponent = ({ title, time, imageSrc, alt, id }) => {
  return (
    <div className="individual-image-component" id={id}>
      <h3>{title}</h3>
      <p>{time}</p>
      <img src={imageSrc} alt={alt} />
    </div>
  );
};

const Gallery = () => {
  return (
    <>
      <Header />
      <div className="gallery">
        <IndividualImageComponent
          title="Horticultural Expo Park, Xi’an, China"
          time="21 February 2016"
          imageSrc={Horticultural}
          alt="Horticultural Image"
        />
        <IndividualImageComponent
          title="Old Melbourne Gaol, Melbourne, Australia"
          time="19 December 2017"
          imageSrc={Gaol}
          alt="Gaol Image"
        />
        <IndividualImageComponent
          title="Sydney Opera House & Harbour Bridge, Sydney, Australia"
          time="12 November 2017"
          imageSrc={OperaHarbour}
          id="operaharbour"
          alt="Sydney Opera House and Harbour Bridge"
        />
        <IndividualImageComponent
          title="Rain Vortex, Singapore"
          time="16 December 2019"
          imageSrc={Singapore}
          alt="Rain Vortex"
        />
        <IndividualImageComponent
          title="Angkor National Museum, Siem Reap, Cambodia"
          time="4 January 2020"
          imageSrc={Angkor}
          alt="Angkor National Museum"
        />
        <IndividualImageComponent
          title="Flinders St Station, Melbourne, Australia"
          time="17 December 2017"
          imageSrc={Flinder}
          id="operaharbour"
          alt="Flinders St Station"
        />
        <IndividualImageComponent
          title="Kamay Botany Bay National Park, Sydney, Australia"
          time="1 January 2021"
          imageSrc={Botany}
          id="botany"
          alt="Kamay Botany Bay National Park"
        />
        <IndividualImageComponent
          title="The Floating Village, Siem Reap, Cambodia"
          time="4 January 2020"
          imageSrc={Floating}
          alt="The Floating Village"
        />
        <IndividualImageComponent
          title="Seoul to Sydney, Flight KE121"
          time="5 October 2017"
          imageSrc={Seoul}
          alt="Seoul to Sydney Flight"
        />
        <IndividualImageComponent
          title="Coogee Beach, Sydney, Australia"
          time="29 October 2017"
          imageSrc={Coogee}
          alt="Coogee Beach"
        />
        <IndividualImageComponent
          title="Sky, Sydney, Australia"
          time="29 January 2018"
          imageSrc={Sky}
          alt="Sky in Sydney"
        />
        <IndividualImageComponent
          title="Circular Quay, Sydney, Australia"
          time="12 November 2017"
          imageSrc={Circular}
          id="operaharbour"
          alt="Circular Quay"
        />
        <IndividualImageComponent
          title="Krivong (Border city), Cambodia"
          time="27 December 2019"
          imageSrc={Krivong}
          alt="Krivong Border City"
        />
        <IndividualImageComponent
          title="Cafe in Xi'an, China"
          time="13 August 2017"
          imageSrc={Cafe}
          id="operaharbour"
          alt="A Cafe in Xi'an"
        />
        <IndividualImageComponent
          title="Sunrise in Sihanoukville, Cambodia"
          time="28 December 2019"
          imageSrc={Sunrise}
          alt="Sunrise in Sihanoukville"
        />
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
