import React from "react";
import "./about.css";
import LaptopIcon from "assets/images/laptop-icon.png";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import UNSWLogo from "assets/images/unsw-logo.png";
import ProfilePhoto from "assets/images/profile.png";
import Header from "components/Header";
import Footer from "components/Footer";

const About = () => {
  return (
    <div>
      <Header />
      <div className="home-about-me">
        <h1>Software Engineer | Full-stack Developer | Tech Enthusiast</h1>
        {/* <div className="home-about-me-profile-text"> */}
        <p>
          <span class="image">
            <img src={ProfilePhoto} alt="Profile Photo" />
          </span>
        </p>
        <p>
          Hello! My name is Vincent, currently working as a Software Engineer at
          Infomedia - a global company offering SaaS solutions in the automotive
          industry. I hold an Honours degree in Software Engineering from the
          University of New South Wales (UNSW).
          <br />
          <br />
          I am passionate about software development and have a keen interest in
          the latest technologies. I am a self-motivated, hard-working, and
          enthusiastic individual with a strong desire to learn and grow. I
          enjoy working in a team environment and have a proven ability to
          deliver high-quality results.
          <br />
          <br />
          Originally from Xi'an, China, I have made Sydney my home since 2017.
        </p>
        {/* </div> */}
        <br />
        <br />
        <br />
        <h2>My Strength - Tools & Skills</h2>
        <p>
          As a tech lover, I have a strong passion for learning new
          technologies. I am proficient in a range of programming languages and
          tools. Below lists some of my strengths and tools that I like to use.
          <ul>
            <li>
              Programming Languages: Java, Python, JavaScript (including
              ReactJS, NodeJS), HTML, CSS/SCSS, Flutter, Bash & Perl, C/C++
            </li>
            <li>
              Frameworks & Libraries: Spring, Hibernate, JUnit, React, Express,
              Bootstrap, Material UI
            </li>
            <li>
              Database & Data Processing: MySQL, PostgreSQL, MongoDB, AWS RDS,
              Airflow, Glue etc.
            </li>
            <li>
              Cloud & DevOps: AWS & GCP, Docker, CircleCI, New Relic,
              CloudWatch, Athena, Lambda, ECS, EC2, S3, Route53, CloudFront, API
              Gateway, etc.
            </li>
            <li>
              Code & Work Management: Postman, IntelliJ, VS Code, Notion, Jira,
              Confluence, GitHub
            </li>
            <li>Methodologies: Microservice, Agile, Scrum, Kanban, TDD, BDB</li>
            <li>
              Multi OS: MacOS, Linux (Debian & Ubuntu), Windows. Skilful in
              Linux command line
            </li>
          </ul>
        </p>

        <br />
        <br />
        <br />
        <h2>Professional Competence</h2>
        <p>
          My career has honed my expertise in software development, emphasising
          the comprehensive aspects of software implementation and delivery.
          This expertise encompasses a range of areas including front-end and
          back-end development, API architecture & implementation, database
          operations, and the development of data processing pipelines.
          <br />
          <br />
          Additionally, I am proficient in utilising cloud tools and
          implementing CI/CD pipelines, primarily with AWS and GCP platforms. My
          responsibilities also extend to production oversight and monitoring,
          utilising tools such as New Relic and CloudWatch. I have a deep
          interest in cutting-edge technologies particularly in fields like
          Natural Language Processing (NLP), Artificial Intelligence (AI),
          Machine Learning (ML), and the Internet of Things (IoT).
          <br />
          <br />
          My journey in technology has been exciting and fulfilling. I love
          creating software that not only solves tough problems but also makes a
          real difference. The mix of technical skills I've equipped and the
          dedication to making a positive impact is at the heart of what I do.
          For more, visit my experience.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default About;
